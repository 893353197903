@import "../../SiteShared.scss";
table {
  border-spacing: 0;
  border: 1px solid #ededed;
}
table tr:last-child td {
  border-bottom: 0;
}
table th,
table td {
  text-align:center;
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
  position: relative;
  // Button{
  //   opacity:0
  // }
}
.logo-tiny {
  -webkit-animation: spin 1000ms infinite linear;
    animation: spin 1000ms infinite linear;
  height: 30px;
  // margin-top: 20px;
  padding-left: 5px;
  padding-right: 10px;

}

.paybillsmodal {
  color:blue !important
}
.paybillsmodal:hover {
  color:black !important
}



@-webkit-keyframes spin {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
  }
}

table tr[role=row]:hover{
  Button{
    background:$accent-color;
    color: #fff;
  }
}
table tr[role=row] {
  .ui {
    &.button:hover {
      background:$accent-color;
      color: #fff;
    }
  }
}



table th:last-child,
table td:last-child {
  border-right: 0;
}
table tr:nth-child(even) {
  background-color: #fafafa;
}
table th{
  background:$primary-color;
  color:$primary-color-light;

  }
  table th::before {
    position: absolute;
    right: 15px;

    top: 16px;
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
  table th.sort-asc::before {
    border-bottom: 5px solid $accent-color-light;
  }
  table th.sort-asc,
  table th.sort-desc {
    background:#4C158A;
  }
  table th.sort-desc::before {
    border-top: 5px solid $accent-color-light;
  }

  .table-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width:100%;
  }
  .badge {
    background-color: #9ae6b4;
    color: #22543d;
    margin-right: 4px;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }
  input {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: none;
  }

  .paginate {
    display:flex !important;
    align-items:flex-end;
    direction: rtl;
    flex-direction:row;
    button{
      margin:2px;
      height:40px;
      width:30px;
      border-color: $primary-color-medium;

      &.paginate-selected {
        background-color: $accent-color;
        color: $primary-color-light;
      }
    }
  }
  .fixed_header{
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
  }
  
  .tableFixHead          { overflow: auto; }
  .tableFixHead thead th { position: sticky; top: 0; z-index: 1; }
  .tableFixHead thead th {background-color: rgb(235 ,235, 235) !important;}

  .fixed_header tbody{
  display:block;
  width: 100%;
  overflow: auto;
  height: 60vh;
  
  }
  
  .fixed_header thead tr {
   display: block;
  }
  
  .fixed_header th, .fixed_header td {
  padding: 5px;
  text-align: left;
  min-width: 100%;
  max-width: 100%;
  }

.pagination {
  position: absolute;
  bottom: 0px;
  &-index {
    position: absolute;
    bottom: 28px;
    left: 0px;
  }
  &-nav {
    background-image: linear-gradient(276deg, #7127c4, #4c158a);
    border-radius: 3px;
    color: #fff;
    height: 20px;
    font-size: 13px;
    font-weight: 600;
    border: none;
    margin: 2px 6.4px 2px 0;
  }
}

.ellipsis {
  width: 8px;
}

.ellipsis::after {
  position: relative;
  left: -2px;
  bottom: 3px;
  display: inline-block;
  content: '...';
}

.loading {
  width: 8px;
}

.loading::after {
  position: relative;
  left: -2px;
  bottom: 3px;
  display: inline-block;
  animation: dotty steps(1,end) 1s infinite;
  content: '';
}

@keyframes dotty {
  0%   { content: ''; }
  25%  { content: '.'; }
  50%  { content: '..'; }
  75%  { content: '...'; }
  100% { content: ''; }
}