@import "../../styles/quillpro/quillpro-variables";
@import "../../styles/_variables";

.nav {
    a {
      color: $qp-color-darkblue;
    }
    a.navbar-brand {
      margin: 1rem 0 0 0;
      padding: 1rem 2rem;
      
    }
    .logo-nav-item {
      padding: 1.25rem 0;
      a {
        margin: 0;
      }
    }
    .nav-item {
      display: inline-block;

      .nav-link {
        @include qp-border-radius(0);
        padding: 1rem 2rem;
        position: relative;
        
        
        
        span.top-folder {
          display: inline-block;
          width: 100%;
        }
        
        span.subfolder {
          
          &-second {
            display: inline-block;
            margin-left: 1rem;
            width: 100%;
          }


          &-third {
            display: inline-block;
            margin-left: 2rem;
            width: 100%;
          }
        }


        i.batch-icon {
          color: $theme-text-color;
          position: relative;
          top: 1px;
          opacity: 0.7;

          &-folder-alt {
            color: $color-leasera-complement-light;
          }

          &-plus {
            transform: translateY(7px) scale(.65);
            position: absolute;
            right:0;
            padding-right: 1.5rem;
          }
        }
      }
      .nav-link:hover,
      .nav-link:focus,
      .nav-link.active {
        i.batch-icon {
          opacity: 1;
        }
      }
      .icon-plus {
        content: "\F01D";
        font-weight: 400;
        font-size: 1.3rem;
        margin-top: -0.22rem;
        float: right;
        opacity: 0.7;
        @include qp-transition(all 300ms);
        
      }
      .nav-link.nav-parent:hover::after,
      .nav-link.nav-parent:focus::after {
        opacity: 1;
      }
      .nav-link.nav-parent + ul.nav {
        display: none;
        // border-left: 5px solid $qp-color-primary-gradient-1;
        .nav-link {
          padding-left: 3.8rem;
          

        }
        ul.nav {
        //   background-color: darken($qp-color-darkblue, 5%);
          .nav-link {
            padding-left: 4.8rem;
          }
          ul.nav {
            // background-color: darken($qp-color-darkblue, 8%);
          }
        }
      }
    }
    .nav-item.open > .nav-link.nav-parent::after {
      opacity: 1;
      @include qp-rotate(-90deg);
    }
    .nav-header {
      color: $qp-color-white;
      text-transform: uppercase;
      padding: 0.5rem 2rem;
      margin: 2rem 0 0 0;
      font-weight: 700;
    }
  }

.custom-file-label::after {
  background-color: $qp-color-primary;
  color: $qp-color-white;
}
.card-background {
  background-color: $theme-text-color !important;
  background-size: 100%;
}
.files input {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
  transition: outline-offset .15s ease-in-out, background-color .15s linear;
  padding: 1.875rem 0px 5.3125rem 35%;
  text-align: center !important;
  margin: 0;
  width: 100% !important;
  height: 9.25rem !important;
  // background: red !important;

}
.files input:focus{     outline: 2px dashed #92b0b3;  outline-offset: -10px;
  -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
  transition: outline-offset .15s ease-in-out, background-color .15s linear; border:1px solid #92b0b3;
}
.files{ 
  position: relative;
  height: 9.25rem !important;
}
.color input{ background-color:#f1f1f1;}
.files:before {
  position: absolute;
  bottom: 10px;
  left: 0;  pointer-events: none;
  width: 100%;
  right: 0;
  height: 3.5625rem;
  content: " or drag and drop it here. ";
  display: block;
  margin: 0 auto;
  color: #2ea591;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}

.dropdown-menu {

  &:hover {
    color: white;
  }
  width: fit-content;
}

.folder {
  
  &-title {
    padding-left: .5rem;
    margin-bottom: -.1rem;
  }
    
  &-tree {
    border-radius: $qp-border-radius-large;
    padding: .5rem 0;
    background: #f7f7f7;
    border: 1px solid #d2d2d2;
    height: 38.75rem;
    overflow: hidden;

    min-width: 15rem;



    .nav-item {

      display: block;

      .nav-link {
        padding: .3rem .5rem;

        &:hover,
        &:active,
        &:focus {
          background: $gray-fade
        }

        .folder-tree-item {
          display: block;
        }
      }
    }

    .mCSB_draggerContainer {
      position: absolute !important;
      background: transparent;
      z-index: 1;
      right: -100%;

      .mCSB_dragger .mCSB_dragger_bar {
        background-color: $qp-color-primary;
      }
      
      .mCSB_dragger {
        height: 1.875rem !important;
      }
    }
  }
}

.file {
  
  &-display-container {
    background: white;
    min-height: 26rem;
    padding-top: 1rem !important;
    border: 1px solid #d2d2d2;
    border-radius: $qp-border-radius-large;

    margin-left: 1rem;

    // FOR TESTING SCROLL BAR
    // height: 100px;
    // background: red;
    //----------------------
    .mCSB_draggerContainer {
      position: absolute !important;
      background: transparent;
      z-index: 1;
      transform: translateX(.6rem);
      // right: -110%;

      .mCSB_dragger .mCSB_dragger_bar {
        background-color: $qp-color-primary;
      }
      
      .mCSB_dragger {
        height: 1.875rem !important;
      }
    }
  }

  &-card {
    padding: .2rem .2rem 0 .2rem;
    
    .dropdown-item:hover {
      color: white !important;
      background: $qp-color-primary !important;
    }
  }

  &-icon {
    float: left;
    width: 2rem;
    margin-right: .2rem;
  }
  
  &-name {
    transform: translateY(3px);
  }

  &-dropdown-toggle#navbar-dropdown-sales-overview-header-button {
    position: absolute;
    display: block;
    z-index: 1;
    width: 100%;
    color: transparent !important;
  }
}

.upload-container { 
  background: white;
  border-radius: $qp-border-radius-large;
  padding: 0;
  margin-bottom: 1rem;
  margin-left: 1rem;
  min-height: 11.8125rem !important;
}


@media screen and (max-width:991px){

  div.col-lg-4 {
    padding-right: 0;
  }

  .folder-tree {
    height: 14rem;
    margin-bottom: 1rem;
  }

}


.file-viewer-modal {
  .pg-viewer-wrapper {
    overflow-y: auto;
  }
  .photo-viewer-container {
    height: auto !important;
    width: 100% !important;
    max-height: 450px !important;
    img {
      width: auto !important;
      height: auto !important;
      max-width: 100%;
      max-height: 450px;
      object-fit: cover;
    }
  }
}

.modals.dimmer .ui.scrolling.modal.file-viewer-modal:not([class*="overlay fullscreen"])::after {
  display: none;
}

.file-manager-item-para {
  span {
    display: inline-block;
    cursor: pointer;
  }
}
.dropdown-item {
  cursor: pointer;
}