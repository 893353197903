@import "../../styles/quillpro/quillpro-variables";
@import "../../styles/_variables";
@import "../../SiteShared.scss";

.properties-container {
    .nav {
        background:none;
        display:inline;
        color:black !important;
        padding-left:0px !important;
    }
    .nav:hover{
        background:none !important;
    }
    .not-allowed {
        opacity:.5 !important;
        pointer-events: none;
        cursor: not-allowed;
        }

    .alert{
        margin: 0.9375rem;
    }
    
    .property-img {
        height: 10.5rem;
        width:100%;
        border-bottom-left-radius: .4673rem !important;
        border-top-left-radius: .4673rem !important;
    }

    a {
        width: 40px;
        padding: 0;
        background: $qp-color-primary;
        border-radius: $qp-border-radius-small;
        color: $qp-color-white !important;
    }

    .batch-icon-list {
        margin: 7px auto;
    }
        .notification-number {
            width: 20px;
            height: 20px;
            display: inline-block;
            border-radius: 50%;
            background: red;
            text-align: center;
            line-height: 20px;
            font-size: 10px;
            color: #ffffff;
            box-shadow: -16px -12px 46px 0px rgba(0,0,0,0.4);
            position: absolute;
            // transform: translateX(60px);
            right:-10px;
            top: -10px;
          }
          
    
          .progress-bar {
            background-color: $qp-color-primary;
            color: red;
          }
}


.paybillsmodaloverview {
  width:85% !important;
  overflow-y:hidden !important;
  margin-left:3vw !important;
  // z-index: 99999999 !important;
  .billscontent {
    overflow-y:auto;

    .paymentsPane {
      margin-bottom:110px !important;
      .ui.dropdown .menu {
        // background:red !important;
        z-index: 999999 !important;
        position:absolute !important;
        right:0 !important;
        left:auto
      }
    }
  }
}
  
.semanticModal {
    top: unset!important;
      right: unset!important;
      bottom: unset!important;
      left: unset!important;
      overflow-y:auto;
      &-request{
        &-header{
          background: $accent-color !important;
        }
        &-footer{
          background:$accent-color-light !important;
        }
        &-submit{
          background: $accent-color !important;
          color:white !important;
        }
        &-close{
          background: $primary-color !important;
          color:$accent-color-light !important;
        }
      }
      .label{
        padding-right:12px !important;
      }
  }
