.ui.fluid.dropdown {
    width: 150px !important;
    height: 45px;
    margin: 0 20px 0 10px;
    padding: 5px 10px 5px 15px;
    border-radius: 3px;
    line-height: normal !important;
    background-color: rgba(#000000, 0.05);
    border: 0;
    display: flex;
    align-items: center;
    position: relative;

    .dropdown.icon {
        top: 10px;
        right: 4px;
        padding:  9px 0 !important;
        &:before {
            content: '';
            background: url('/assets/img/arrow-down-small.svg') no-repeat !important;
            width: 25px;
            height: 25px;
            display: block;
        }
    }

    .text {
        font-size: 12px;
        font-weight: 500;
        color: rgba(#171a1d, 0.7);

    }
}
.custom-dropdown {
  position: relative;

  .more-btn {
      width: 45px;
      height: 50px;
      border-radius: 3px;
      background-color: rgba(#000000, 0.03);
      display: block;
      line-height: 43px;
  }

  .dropdown-list {
    //   display: "none";
      width: 275px;
      padding: 10px 25px 10px 25px;
      border-radius: 3px;
      box-shadow: 0 11px 14px -10px #e5eced;
      background-color: #ffffff;
      position: absolute;
      right: 0;
      text-align: left;

      .list-item {
          a {
              font-size: 13px;
              font-weight: 500;
              color: #5e6267;
              display: flex;
              align-items: center;
              padding: 7px 0;

              img {
                  margin-right: 10px;
              }
          }

          &.red-text {
              a {
                  color: #ef2c2c;
              }
          }
      }

  }
}
