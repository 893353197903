@import "../../SiteShared.scss";

  .main-calendar {
    font-size: 16px;
    height: auto;
    .calendar-header.ui.menu {
      color: $primary-color-light;
      background-color: $primary-color;

      .month-label {
        font-size: 1.5em;
        line-height: 40px;
      }

      .calendar-action.item {
        color: $primary-color-light;
      }
    }

    .calendar-body {
      &.ui.attached.segment {
        max-width: 100%;
      }

      .calendar-dow {
        background-color: $primary-color-light;
        color: $primary-color;
        font-size: 1.25em;
      }
    }

    .events-daily {
      .event-card.ui.card {
        width: 100%;
        text-align: left;
        background-color: $primary-color-light;
        font-size: 1em;

        .event-delete {
          float: right;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  } //End of calendar container

  .days-title {
      color:$primary-color;
      width:100%
  }

  .day {
    min-height: 100px;
    border: $primary-color-light solid  1px;

    &:hover {
      background-color: $accent-color-light;
      .day-number {
        cursor: default;
      }
    }

    &.today {
      background: $accent-color-light;
      .day-number {
        color: $primary-color;
      }
    }

    &.before {
      .day-number {
        color: $primary-color-medium;
      }
    }

    &.different-month {
      opacity: 0.25;
      .day-number {
        color: $primary-color-medium;
      }
    }

    .day-number {
      font-size: 1.25em;
    }

    .event-label {
      opacity: 0.5;
      padding-top: 15px;
    }
  }
  .dashboard-cal {
    .calendar-dow{
      font-size: 1.00em !important;
    }
    .main-calendar {
     .seven {
       padding-right:0px !important;
       padding-left:0px !important;
     }
      // max-height:0px !important;
    }
    .day {
      min-height: 40px !important;
      // max-height:20px;
      border: $primary-color-light solid  1px;
      padding-top:0px !important;
      padding-bottom:0px !important;
      padding-right:0px !important;
      padding-left:0px !important;
      &:hover {
        background-color: $accent-color-light;
        .day-number {
          cursor: default;
        }
      }
      &.chosen {
        border: $accent-color-medium solid  1px;
        background-color: $accent-color-light;
      }
      &.today {
        background: $accent-color-medium;
        .day-number {
          color: $primary-color;
        }
      }
  
      &.before {
        .day-number {
          color: $primary-color-medium;
        }
      }
  
      &.different-month {
        opacity: 0.25;
        .day-number {
          color: $primary-color-medium;
        }
      }
  
      .day-number {
        font-size: 1.00em;
      }
  
      .event-label {
        opacity: 0.5;
        padding-top: 0px !important;
        
      }
    }
  }