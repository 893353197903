.resident-container,
.maintenance-table,
.applications-table {
    .table-container {
        padding: 0px;
    }
    .heading {
        font-size: 20px;
        font-weight: 800 !important;
        color: #171a1d;
    }
    h6 {
        font-size: 13px;
        font-weight: normal;
        color: #5e6267;
    }
    table {
        border-collapse: separate !important;
        border-spacing: 0 15px;
        border: 0px;
        min-height:200px;
        thead {
            tr {
                background-color: rgba(0, 0, 0, 0.05);
                th {
                    width:100% !important;
                    // background-color: unset;
                    color: RGB(120, 109, 109);
                    font-size: 11px;
                    font-weight: 500;
                    font-family: "Be Vietnam", sans-serif !important;
                    text-transform: uppercase;
                    text-align: left;
                    &:last-child {
                        // width: 15%;
                        text-align: left;
                    }
                }
            }
        }
        tbody {
            tr {
                border-radius: 3px;
                box-shadow: 0 11px 14px -10px #e5eced;
                background-color: #ffffff;
                height: 80px;
                td {
                    border: 0px;
                    font-family: "Be Vietnam", sans-serif !important;
                    color: #5e6267;
                    font-size: 13px;
                    font-weight: 500;
                    word-break: break-word;
                    &:first-child {
                        width: 17%;
                        // text-align: left;
                        padding-left: 25px;
                        h6 {
                            font-size: 14px;
                            font-weight: 600 !important;
                            color: #171a1d;
                            margin-bottom: 0px;
                        }
                        h5 {
                            margin: 0px;
                            font-size: 13px !important;
                            font-weight: 500 !important;
                            color: #5e6267;
                        }
                    }
                    &:last-child {
                        width: 15%;
                        // text-align: left;
                        button {
                            width: 120px;
                            height: 30px;
                            background-color: rgba(0, 0, 0, 0.05);
                            font-size: 11px;
                            font-weight: bold;
                            color: #5e6267;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }
}

.finance-container {
    .table-container {
        padding: 0px;
    }
    .heading {
        font-size: 20px;
        font-weight: 800 !important;
        color: #171a1d;
    }
    h6 {
        font-size: 13px;
        font-weight: normal;
        color: #5e6267;
    }
    table {
        border-collapse: separate !important;
        border-spacing: 0 15px;
        border: 0px;
        width:100%;
        thead {
            tr {
                background-color: rgba(0, 0, 0, 0.05);
                th {
                    background-color: unset;
                    color: #5e6267;
                    font-size: 11px;
                    font-weight: 500;
                    font-family: "Be Vietnam", sans-serif !important;
                    text-transform: uppercase;
                    &:last-child {
                        width: 15%;
          
                    }
                }
            }
        }
        tbody {
            tr {
        //         border-radius: 3px;
        //         box-shadow: 0 11px 14px -10px #e5eced;
        //         background-color: #ffffff;
        //         height: 80px;
                td {
        //             border: 0px;
        //             font-family: "Be Vietnam", sans-serif !important;
        //             color: #5e6267;
        //             font-size: 13px;
        //             font-weight: 500;
                    text-align: left;
        //             &:first-child {
        //                 // width: 17%;
        //                 // text-align: left;
        //                 // padding-left: 25px;
        //                 h6 {
        //                     font-size: 14px;
        //                     font-weight: 600 !important;
        //                     color: #171a1d;
        //                     margin-bottom: 0px;
        //                 }
        //                 h5 {
        //                     margin: 0px;
        //                     font-size: 13px !important;
        //                     font-weight: 500 !important;
        //                     color: #5e6267;
        //                 }
        //             }
                   
                }
            }
        }
    }
}

.roll-container {
    .table-container {
        padding: 0px;
    }
    .heading {
        font-size: 20px;
        font-weight: 800 !important;
        color: #171a1d;
    }
    h6 {
        font-size: 13px;
        font-weight: normal;
        color: #5e6267;
    }
    table {
        border-collapse: separate !important;
        border-spacing: 0 15px;
        border: 0px;
        width:100%;
        min-height:200px;
        thead {
            tr {
                background-color: rgba(0, 0, 0, 0.05);
                th {
                    text-align:left !important;
                    background-color: unset;
                    color: #5e6267;
                    font-size: 11px;
                    font-weight: 500;
                    font-family: "Be Vietnam", sans-serif !important;
                    text-transform: uppercase;
                    &:last-child {
                        // width: 15%;
                        // text-align: left;
                    }
                    &.lease-list-th{
                        &:last-child{
                            width: auto;
                        }
                    }
                }
            }
        }
        tbody {
            tr {
                border-radius: 3px;
                box-shadow: 0 11px 14px -10px #e5eced;
                background-color: #ffffff;
                height: 80px;
                td {
                    text-align:left !important;
                    border: 0px;
                    font-family: "Be Vietnam", sans-serif !important;
                    color: #5e6267;
                    font-size: 13px;
                    font-weight: 500;
                    word-break: break-word;
                    &:first-child {
                        width: 17%;
                        // text-align: left;
                        padding-left: 25px;
                        h6 {
                            font-size: 14px;
                            font-weight: 600 !important;
                            color: #171a1d;
                            margin-bottom: 0px;
                        }
                        h5 {
                            margin: 0px;
                            font-size: 13px !important;
                            font-weight: 500 !important;
                            color: #5e6267;
                        }
                    }
                    &:last-child {
                        // width: 15%;
                        left: 0 !important;

                        button {
                            // width: 120px;
                            height: 30px;
                            background-color: rgba(0, 0, 0, 0.05);
                            font-size: 11px;
                            font-weight: bold;
                            color: #5e6267;
                            text-transform: uppercase;
                            margin-bottom: 2px;
                        }
                    }
                    time{
                        word-break: break-word;
                    }
                }
            }
        }
    }
}
.box-score-table-title{
    text-transform: uppercase;
    color: #171a1d;
}
.reconcile-container {
    .table-container {
        padding: 0px;
    }
    .heading {
        font-size: 20px;
        font-weight: 800 !important;
        color: #171a1d;
    }
    h6 {
        font-size: 13px;
        font-weight: normal;
        color: #5e6267;
    }
    table {
        border-collapse: separate !important;
        border-spacing: 0 15px;
        border: 0px;
        width:100%;
        min-height:200px;
        thead {
            tr {
                background-color: rgba(0, 0, 0, 0.05);
                th {
                    text-align:left !important;
                    background-color: unset;
                    color: #5e6267;
                    font-size: 11px;
                    font-weight: 500;
                    width:10% !important;
                    font-family: "Be Vietnam", sans-serif !important;
                    text-transform: uppercase;
                    &:last-child {
                        width: 15%;
                        // text-align: left;
                    }
                }
            }
        }
        tbody {
            tr {
                border-radius: 3px;
                box-shadow: 0 11px 14px -10px #e5eced;
                background-color: #ffffff;
                height: 80px;
                td {
                    text-align:left !important;
                    border: 0px;
                    font-family: "Be Vietnam", sans-serif !important;
                    color: #5e6267;
                    font-size: 13px;
                    font-weight: 500;
                    word-break: break-word;
                    &:first-child {
                        width: 17%;
                        // text-align: left;
                        padding-left: 25px;
                        h6 {
                            font-size: 14px;
                            font-weight: 600 !important;
                            color: #171a1d;
                            margin-bottom: 0px;
                        }
                        h5 {
                            margin: 0px;
                            font-size: 13px !important;
                            font-weight: 500 !important;
                            color: #5e6267;
                        }
                    }
                    &:last-child {
                        width: 15%;
                        button {
                            
                            // width: 120px;
                            height: 30px;
                            background-color: rgba(0, 0, 0, 0.05);
                            font-size: 11px;
                            font-weight: bold;
                            color: #5e6267;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }
}


// Resident Detail CSS
.page-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    .page-back{
        width: 45px;
        height: 45px;
        padding: 10px;
        border-radius: 3px;
        background-color: #0000000d;
        margin-right: 15px;
        cursor: pointer;
    }
    .page-header-title{
        font-size: 20px;
        font-weight: 800;
        line-height: 1;
        color: #171a1d;
    }
    .page-header-subtitle{
        font-size: 13px;
        line-height: 1.54;
        color: #171a1d;
    }
    .page-subtitle-light{
        font-size: 13px;
        color: #5e6267;
        margin-top: 4px;
    }
    .page-action{
        width: 45px;
        height: 45px;
        padding: 10px;
        border-radius: 3px;
        background-color: #0000000d;
        margin-left: 10px;
        cursor: pointer;
    }
    .header{
        font-size: 14px !important;
        margin-bottom: 3px !important;
    }
    .rdt{
        height: 36px;
        border-radius: 3px;
    }
    .form-control{
        border: solid 1px #e3e3e3;
        margin-bottom: 0;
        height: 17px;
        max-width: 130px;
        box-shadow: none !important;
    }
    .button{
        text-transform: uppercase !important;
        font-size: 13px !important;
        border-radius: 3px !important;
    }
}
