@import "../../styles/quillpro/quillpro-variables";
@import "../../styles/variables";

.reports-list-row{
    .reports-list-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px 30px;
        cursor: pointer;
        border-radius: 3px;
        box-shadow: 0 11px 14px -10px #e5eced;
        background-color: #fff;
        margin-bottom: 20px;
        &::after{
            content: '';
            background-image: url('../../assets/images/right-arrow.svg');
            width: 25px;
            height: 25px;
            transform: rotate(-90deg);
        }
        .report-type{
            font-weight: 500;
            color: #212121;
        }
    }
}

// Balance Sheet css
.graph-card{
    padding: 25px 19px 30px 20px;
    border-radius: 3px;
    box-shadow: 0 11px 14px -10px #e5eced;
    background-color: #fff;
    .graph-card-title{
        font-size: 17px;
        font-weight: 800;
        color: #3b1c5a;
    }
}
.reconcile-container {
    &.balance-sheet-table{
        table{
            min-height: unset;
            tbody{
                td{
                    padding: 0 !important;
                }
            }
        }
    }
    .balance-sheet-table-list{
        
        .card{
            background-color: #fff;
            border-radius: 0;
            box-shadow: none;
            border: 0;
            .card-header{
                background-color: #fff;
                border-bottom: 1px solid #5e63681a;
                a{
                    font-size: 14px;
                    font-weight: 600;
                    color: #3b1c5a;
                    display: block;
                    img{
                        margin-right: 10px;
                    }
                }
               
            }
            
            .card-body{
                padding: 0;
            }
                
        }
        #accordion-1{
            & > .card{
                & > .card-header{
                    padding-left: 47px;
                    background-color: #f2f1f4;
                }
            }
        }
        #accordion-1-1, #accordion-1-2{
            & > .card{
                & > .card-header{
                    padding-left: 78px;
                }
                .card-body{
                    padding: 13px;
                    padding-left: 116px;
                    border-bottom: 1px solid #efeff0;
                }
            }
        }
    }
}
