
@import "../../SiteShared.scss";

table {
    border-spacing: 0;
    -moz-box-sizing: border-box !important;
    border-collapse: separate !important;
    border: 1px solid #ededed;
  }
  table tr:last-child td {
    border-bottom: 0;
    -moz-box-sizing: border-box !important;
  }
  table th,
  table td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid #ededed;
    border-right: 1px solid #ededed;
    position: relative;
    -moz-box-sizing: border-box !important;
  }

  .table-container.application-table .lead-table-list tr th {
    background-color: $primary-color;
  }


  .table-container.px-0 tr:nth-child(2) th {
    background-color: $primary-color;
  }

  table th:last-child,
  table td:last-child {
    border-right: 0;
  }
  table tr:nth-child(even) {
    background-color: #fafafa;
  }

  table th::before {
    -moz-box-sizing: border-box !important;
    position: absolute;
    right: 15px;
    top: 16px;
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }
  table th.sort-asc::before {
    border-bottom: 5px solid $accent-color-light;
  }
  table th.sort-desc::before {
    border-top: 5px solid $accent-color-light;
  }

  .table-container {
    -moz-box-sizing: border-box !important;
    display: flex;
    flex-direction: column;
    padding: 20px;
    width:100%;
  }
  .badge {
    background-color: #9ae6b4;
    color: #22543d;
    margin-right: 4px;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }
  input {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: none;
  }

  .last-name-accessor:hover{
    cursor: pointer;
    color: $accent-color !important;
  }
  .paginate {
    display:flex !important;
    align-items:flex-end;
    direction: rtl;
    flex-direction:row;
    button{
      margin:2px;
      height:40px;
      width:30px;
    }
  }
