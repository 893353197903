/* 
	DO NOT DELETE
	THIS SCSS/CSS FILE IS USED BY SCSS FILES WITHING THE React/view AND React/layout folders
	
 */
/* QUILLPRO VARIABLES */
/* 
	DO NOT DELETE
	THIS SCSS/CSS FILE IS USED BY SCSS FILES WITHING THE React/view AND React/layout folders
	
 */
/* QUILLPRO MIXINS */
/* Colors */
@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam:wght@300;400;500;600;700;800&display=swap");
@font-face {
  font-family: 'Avenir-light', sans-serif;
  font-weight: 300;
  font-display: swap;
  src: url(/assets/fonts/Avenir-Book.ttf); }

@font-face {
  font-family: 'Avenir-regular', sans-serif;
  font-weight: 400;
  font-display: swap;
  src: url(/assets/fonts/Avenir-Roman.ttf); }

@font-face {
  font-family: 'Avenir-bold', sans-serif;
  font-weight: 600;
  font-display: swap;
  src: url(/assets/fonts/Avenir-Medium.ttf); }

@font-face {
  font-family: 'Avenir-black', sans-serif;
  font-weight: 900;
  font-display: swap;
  src: url(/assets/fonts/Avenir-Heavy.ttf); }

/* 
	DO NOT DELETE
	THIS SCSS/CSS FILE IS USED BY SCSS FILES WITHING THE React/view AND React/layout folders
	
 */
/* QUILLPRO MIXINS */
.leases-container .application-card-grid {
  display: grid;
  grid-template-columns: fit-content(5.2rem) auto auto fit-content(5.2rem);
  grid-template-areas: "img adr ale but"; }
  .leases-container .application-card-grid .img {
    grid-area: img; }
    .leases-container .application-card-grid .img .property-img {
      width: 5.2rem;
      border-bottom-left-radius: .4673rem !important;
      border-top-left-radius: .4673rem !important; }
  .leases-container .application-card-grid .but {
    grid-area: but; }
  .leases-container .application-card-grid .ale {
    grid-area: ale;
    align-self: end;
    justify-self: end;
    padding-right: 1rem;
    font-size: 1.5rem; }
  .leases-container .application-card-grid .adr {
    grid-area: adr;
    align-self: center; }

.leases-container table.table-hover tbody tr:hover.tenant-row {
  color: white; }

.leases-container .table-applications {
  border-collapse: separate;
  border-spacing: 0;
  border: solid #122f3b 2px;
  border-radius: .4673rem !important; }

.leases-container .adr {
  font-size: 1.5rem;
  display: inline-block; }
  .leases-container .adr span {
    vertical-align: bottom; }
  .leases-container .adr .alert {
    margin: 0;
    margin-top: 1rem;
    vertical-align: text-bottom; }

@media screen and (max-width: 767px) {
  .leases-container .application-card-grid .adr {
    display: grid;
    grid-template: auto / 1fr; }
    .leases-container .application-card-grid .adr span {
      font-size: 1.2rem; }
  .leases-container .application-card-grid .ale {
    font-size: 1.2rem; } }

@media screen and (max-width: 992px) {
  .leases-container .application-card-grid {
    display: grid;
    grid-template-areas: "img adr adr ale" "but but but but"; }
    .leases-container .application-card-grid .img {
      grid-area: img; }
    .leases-container .application-card-grid .but {
      grid-area: but;
      width: 100%; }
      .leases-container .application-card-grid .but button {
        transform: translate(3px, 6px);
        width: 100%; }
    .leases-container .application-card-grid .ale {
      grid-area: ale;
      justify-self: end; }
    .leases-container .application-card-grid .adr {
      grid-area: adr;
      font-size: 1.2rem; } }

.lease-no-result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 125px); }

.lease-table-listing th.table-header:nth-child(2) .header-filter-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: center; }
