
@import "./_variables";
/* points cards */

.point-col {
  width: auto;
  p {
    width: 100%;
    color: $black;
    font-size: 12px;
  }
  h2 {
    color: $black;
    font-size: 30px;
    margin: 0;
    line-height: 30px;
  }
  span {
    width: 15px;
    height: 15px;
    display: inline-block;
    background: $primary-color;
    border-radius: 50%;
    text-align: center;
    line-height: 15px;
    color: $white;
    font-size: 10px;
    margin-left: 3px;
    position: relative;
    top: -7px;
  }
}
.card {
  height: auto !important;
  .card-title {
    font-size: 16px;
  }
  .card-title-small{
    margin-top: 26px;
    font-size: 13px;
    color: $theme-text-color;
    strong {
      font-family: $bold-font;
    }
  }
  .card-image-box {
    height: 197px;
    position: relative;
    .card-img-top {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &.property-card {
    .property-size {
      display: flex;
      .item {
        display: inline-flex;
        align-items: center;
        img {
          max-height: 14px;
          margin-right: 8px;
        }
        span {
          font-size: 15px;
        }
        &:after {
          content: '|';
          margin: 0 10px;
          color: rgba(2, 30, 64, 0.2);
        }
        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }
  &.horizontal-card {
    .card-body {
      display: flex;
      padding: 0;
      .card-image {
        width: 200px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .property-details {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 60%;
      }
      .card-details {
        width: calc(100% - 160px);
        padding: 15px;
        .date-applied-wrapper {
          padding-top: 20px;
          width: 40%;
          text-align: right;
          flex: none;
          button {
            margin: 0 0 15px 0;
          }
          p {
            font-size: 12px;
          }
        }
        .property-size {
          padding: 10px 0;
        }
      }
    }
  }
  &.offer-card {
    .offer-items {
      padding: 0 10px;
      flex-grow: 1;
      .item {
        width: calc(100% / 3);
        padding: 10px;
        border-right: 1px solid $gray-fade;
        h3 {
          font-size: 20px;
          margin: 0;
          line-height: 25px;
        }
        p {
          font-size: 12px;
          margin: 0;
        }
      }
    }
    .gift-icon {
      flex: none;
    }
    .arrow-icon {
      flex: none;
      cursor: pointer;
    }
  }
}
.my-fav-property {
  .card {
    .card-title {
      &.title-bottom {
        position: absolute;
        top: -36px;
        right: 0;
        background: $theme-text-color;
        height: 40px;
        text-align: center;
        line-height: 40px;
        color: $white;
        width: auto;
        padding: 0 20px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
  }
  .property-details {
    padding-top: 20px;
  }
}
.btn-span {
  color: $primary-color;
}

// New CSS
.theme-card{
  &.card{
    box-shadow: 0 11px 14px -10px #e5eced;
    background-color: #fff;
    margin-bottom: 30px;
    .card-header{
      padding: 17px 20px 16px;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      background-color: #f4f4f4;
      border: 0;
      .card-header-title{
        font-size: 12px;
        font-weight: bold;
        color: #171a1d;
        text-transform: uppercase;
      }
      .card-profile-header{
        padding: 13px 20px;
      }
    }
    .card-body{
      padding: 20px;
      .card-list{
        .card-list-item{
          padding: 20px 0;
          border-bottom: 1px dashed #0000001a;
          &:last-child{
            border-bottom: 0;
            padding-bottom: 0;
          }
          &:first-child{
            padding-top: 0;
          }
          .list-title{
            font-size: 14px;
            font-weight: 600;
            color: #171a1d;
          }
          .list-text{
            font-size: 13px;
            font-weight: 500;
            color: #5e6267;
          }
          .list-text-dark{
            @extend .list-text;
            color: #171a1d;
          }
          .card-text-light{
            font-size: 12px;
            color: #5e6267;
          }
        }
      }
      .card-profile-body{
        padding: 10px 20px;
      }
    }
  }
}

.services-list{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -7.5px;
  .services-list-item{
    font-size: 13px;
    font-weight: 500;
    color: #5e6267;
    border-radius: 15px;
    background-color: #5e62670d;
    padding: 4px 15px 7px;
    margin: 0 7.5px 15px;
  }
}
.resident-profile-icon{
  margin-right: 20px;
  padding: 18px 17px 18px 18px;
  border-radius: 3px;
  background-color: #e3e0e6;
}
.profile-title{
  font-size: 18px;
  font-weight: bold;
  color: #3b1c5a;
}
.profile-subtitle{
  font-size: 13px;
  font-weight: 500;
  color: #5e6267;
  margin-top: 1px;
}
.resident-account-status{
  padding: 7px 11px 6px;
  border-radius: 15px;
  background-color: #dfede9;
  font-size: 12px;
  font-weight: bold;
  color: #11ae7d;
  text-transform: uppercase;
  margin-left: 20px;
}
.profile-basic{
  margin-bottom: 23px;
}
.profile-label{
  font-size: 14px;
  color: #5e6267;
  margin-bottom: 5px;
}
.profile-label-value{
  font-size: 14px;
  line-height: 1.79;
  color: #181b20;
}
.profile-label-row{
  display: flex;
  align-items: center;
  margin: 9px 0 30px;
}
.profile-label-title{
  font-size: 16px;
  font-weight: bold;
  color: #181b20;
}
.profile-label-subtitle{
  font-size: 13px;
  color: #5e6267;
  margin-left: 5px;
}
.profile-label-line{
  height: 1px;
  border-bottom: 1px dashed #0000001a;
  flex: 1 1 auto;
  margin-left: 20px;
  margin-top: 4px;
}
