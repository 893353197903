.home-banner {
    max-height: 400px;
    .slick-list {
        max-height: 440px;
    }
    .slick-slide {
        position: relative;
    }
    img {
        max-height: 400px;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
    }

    .carousel-caption {
        width: 567px;
        text-align: left;
        bottom: auto;
        top: 50%;
        left: 4%;
        transform: translate(10px, -50%);
        h5 {
            font-size: 25px !important;
            font-weight: 800 !important;
            color: #fff;
        }
        p {
            opacity: 0.8;
            font-size: 14px;
            line-height: 2;
            margin: 0px 0 20px;
            font-weight: 500;
            color: #fff;
            max-width: 485px;
        }
    }

    .slick-dots {
        padding: 5px 0;
        opacity: 0.9;
        border-radius: 8px;
        width: 70px;
        left: 50%;
        right: auto;
        transform: translate(-50%, -20px);
        margin: 0;
        background-color: #171a1d;
        height: 16px;
        bottom: -5px;
        li {
            width: 6px;
            height: 6px;
            border: 0;
            border-radius: 100%;
            button {
                width: 6px;
                height: 6px;
                padding: 0px;
                &:before {
                    line-height: 6px;
                    top: -10px;
                    width: 6px;
                    height: 6px;
                    opacity: 0.3;
                    color: #fff;
                }
            }
            &.slick-active {
                button {
                    &:before {
                        opacity: 1;
                        color: #fff;
                    }
                }
            }
        }
    }
}
.property-manager-wrapper {
    .home-widgets {
        background-color: #ffffff;
    }
    .widget-rw {
        border-radius: 3px;
        background-color: #f5f5f5;
        height: 256px;
        padding: 0px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        &:hover {
            box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
            background-color: #ffffff;
        }
        .widget-image {
            background-color: transparent;
            box-shadow: unset;
            padding: 0px;
            width: auto;
            height: auto;
            &.cost-saving {
                img {
                    width: 35px;
                }
            }
        }
        .widget-content {
            h4 {
                font-size: 16px;
                font-weight: 800;
                margin-top: 25px;
                margin-bottom: 5px;
                line-height: 24px;
            }
            p {
                line-height: 24px;
                font-weight: normal;
            }
        }
    }
    .home-image-section {
        background-color: #fafafa;
        .image-container {
            height: 390px;
            .fixed-left-image {
                right: -50px;
                left: unset;
                bottom: 40px;
                z-index: 999;
            }
            .fixed-square-image {
                bottom: 0px;
                top: -30px;
                z-index: 9;
            }
            .main-image {
                position: absolute;
                right: 0px;
                width: 98%;
                height: 100%;
                z-index: 99;
            }
        }
    }
    .half-image-section {
        box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.05);
        background-color: #fff;
        margin-top: 20px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        padding: 50px !important;
        margin-bottom: 20px !important;
        .column {
            box-shadow: unset !important;
            border: 0px;
            display: flex!important;
            flex-direction: column;
            justify-content: center;
            &.row {
                padding: 0px;
            }
        }
        h3 {
            font-size: 25px;
            font-weight: 800 !important;
            line-height: 1.5;
            color: #3b1c5a;
            margin-bottom: 10px;
            font-family: "Be Vietnam", sans-serif;
        }
        .text {
            line-height: 2.12;
            color: #5e6267;
            margin-bottom: 10px;
            padding-right: 40px;
        }
        &.manage-properties-section{
            padding-top: 85px!important;
            padding-bottom: 75px!important;
        }
    }
}
.win-container {
    border-radius: 3px;
    background-color: #f5f5f5;
    margin-top: 50px;
    position: relative;
    .win-rw {
        position: absolute;
        background-color: #f5f5f5;
        right: 0px;
        z-index: 99;
    }
    .fixed-left-image {
        width: 305px;
        position: absolute;
        top: 190px;
        left: -70px;
        z-index: 9;
    }
    .main-image {
        position: absolute;
        top: 0px;
        right: -25px;
    }
    .fixed-square-image {
        position: absolute;
        bottom: -28px;
        left: -28px;
    }
    .content-rw {
        text-align: center;
        padding: 419px 65px 60px;
        background-color: #f5f5f5;
        h4 {
            font-size: 20px;
            font-weight: bold;
            color: #171a1d;
        }
        p {
            font-size: 15px;
            font-weight: normal;
            line-height: 28px;
            color: #5e6267;
        }
    }
}
.management-wrapper {
    border-radius: 3px;
    background-color: #f5f5f5;
    width: 98%;
    float: right;
    margin-top: 50px;
    position: relative;
    padding-top: 10px;
    img {
        width: 230px;
        margin: 0px auto;
        text-align: center;
        display: block;
        &.fixed-middle-image {
            width: 200px;
            margin-left: 190px;
            margin-top: -10px;
        }
    }
    .content-rw {
        text-align: center;
        padding-bottom: 61px;
        padding-top: 35px;
        h4 {
            font-size: 20px;
            font-weight: bold;
            color: #171a1d;
        }
        p {
            font-size: 15px;
            font-weight: normal;
            line-height: 28px;
            color: #5e6267;
            max-width: 360px;
            margin: 0px auto;
        }
    }
}

.homebtn {
    background-image: linear-gradient(98deg, #3b1c5a, #374db1 163%);
    color: #fff !important;
    min-width: 200px;
    font-size: 13px;
    font-weight: 800;
    border-radius: 3px;
    padding: 12px 0 14px;
    text-transform: uppercase;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    &:hover {
        text-decoration: none;
    }
}

.property-section {
    h3 {
        max-width: 340px;
    }
    .image-container {
        height: 100%;
        position: relative;
        .fixed-top-image {
            position: absolute;
            right: 95px;
            top: 38px;
        }
        .fixed-square-image {
            right: auto;
            left: -29px;
            top: -29px;
            bottom: auto;
        }
        .fixed-left-image {
            left: auto;
            bottom: 40px;
            position: absolute;
            right: 35px;
        }
    }
}
.activity-section {
    .image-container {
        position: relative;
        text-align: right;
    }
    .fixed-left-image {
        position: absolute;
        bottom: 40px;
        left: 95px;
    }
    h3 {
        max-width: 370px;
    }
}
.lease-management-section {
    background-color: #ffffff !important;
    p {
        max-width: 650px;
        margin: 0px auto 50px !important;
    }
    .win-container {
        .main-image {
            top: 20px;
            right: -29px;
            width: 100%;
        }
        .content-rw {
            padding: 459px 65px 60px;
            h4 {
                margin-top: 0px;
            }
            p {
                margin-bottom: 0px !important;
            }
        }
        .fixed-left-image {
            top: 100px;
            left: -40px;
            width: auto;
        }
        .fixed-square-image {
            z-index: -1;
            bottom: auto;
            top: -28px;
        }
    }
    .image-container {
        height: 643px;
        .fixed-top-image {
            width: 305px;
            z-index: 9;
            position: absolute;
            right: -32px;
            top: 75px;
            z-index: 99;
        }
        .fixed-square-image {
            bottom: -78px;
            top: auto;
            z-index: 9;
            right: 8px;
        }
        .renter-image {
            position: absolute;
            right: 0px;
            top: 50px;
            z-index: 9;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
.manage-properties-section {
    background-image: linear-gradient(94deg, #3b1c5a, #374db1 163%);
    margin-top: 50px;
    position: relative;
    margin-bottom: 90px;
    .badge-btn {
        width: 200px;
        height: 30px;
        background-color: #11ae7d;
        padding: 0px;
        border-radius: 15px;
        font-size: 12px;
        font-weight: bold;
        line-height: 30px;
        color: #ffffff!important;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }
    h4 {
        font-size: 40px;
        font-weight: 800;
        color: #ffffff;
        max-width: 441px;
        line-height: 60px;
        margin-bottom: 26px;
    }
    p {
        font-size: 16px;
        font-weight: normal;
        line-height: 36px;
        color: #ffffff;
        max-width: 470px;
        opacity: 0.7;
        max-width: 432px;
        width: 100%;
    }
    .image-container {
        text-align: right;
        position: relative;
        img{
            position: absolute;
    bottom: -262px;
    right: -79px;
        }
    }
    h3{
        color: #ffffff!important;
        max-width: 400px;
        width: 100%;
    }
}
