@import "../../SiteShared.scss";


.disabled-link {
  pointer-events: none;
}
.leftnav.ui.menu.fixed {
  background: unset;
  // width: $leftnav-width-expanded;
  transition: width 0.5s;
  margin-top: 0px;
  overflow-y: auto;
  padding-bottom: $topnav-height;
  padding-top: 10px;
  text-align: center;
  top: 20px;
  //Adjust leftnav size and layout for phone screen size
  @include for-phone {
    margin-top: $topnav-height;
    transition: 0.5s ease-in-out;

    .logo {
      display: none;
    }
  }
  a[href="#/accounting/disable"] {
    pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: black;
    .item {
      padding-left:35px;
      font-weight: bolder;
      .arrow {
        color:lightslategray!important;
      }
      .leftnav-label{
        color:lightslategray!important;
      }
    }
  }

  //Logo
  .logo {
    width: 100%;
    
    padding-top: 0px;
    padding-bottom: 20px;
  }

  //Small logo used when leftnav is collapsed
  .logo-small {
    display: none;
    width: 100%;
  }

  //When the leftnav is collapsed
  &.collapsed {
    //On a phone we don't show it when collapsed
    @include for-phone {
      width: 0px;
      transition: 0.5s ease-in-out;
      visibility: hidden;
    }

    //When it is not on a phone we show it
    @include for-desktop {
      transition: width 0.5s;
      width: $leftnav-width-collapsed;

      //style and hide the label as we want it to flyout on hover
      .item .leftnav-label {
        display: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .submenu {
        .item {
          padding-left: 18px;
          height: 40px;
          .leftnav-label {
            line-height: 40px;
          }
        }

        //on hover flyout the sub menu label
        .item:hover {
          width: 200px;
          height: 40px;
          .leftnav-label {
            display: block;
            position: fixed;
            left: 54px;
            background-color: $menu-background-color-hover;
            z-index: 999;
            padding: 13px 5px 13px 10px;
            height: 40px;
            width: 150px;
            text-align: left;
            margin-top: -7px;
            padding-top: 0px;
          }
        }
      }

      //on hover flyout the label
      .item:hover {
        width: 200px;
        .leftnav-label {
          display: block;
          position: fixed;
          left: 54px;
          background-color: $menu-background-color-hover;
          z-index: 999;
          padding: 13px 5px 13px 10px;
          width: 150px;
          height: 50px;
          text-align: left;
          margin-top: 5px;
        }
      }

      //display small logo when leftnav is collapsed
      .logo-small {
        display: block;
        margin: auto;
        padding-bottom: 35px;
      }

      //don't display full logo when leftnav is collapsed
      .logo {
        display: none;
      }
    }
  }

  //Active submenu styling
  .submenu-active {
    .item {
      background: $menu-background-color-sub-active;
      .leftnav-label {
        color: $menu-font-color-selected ;
      }
      span {
        color: $menu-font-color-sub-active;
        i.icon {
          color: $menu-font-color-selected;
        }
      }

      &.disabled {
        span,
        i.icon {
          color: $menu-font-color-disabled !important;
          pointer-events: none;
        }
      }
    }

    .active .item {
      i.icon {
        color: $menu-font-color-selected;
      }
    }

    .submenu {
      .item {
        color: $menu-font-color-sub-active;
        .icon {
          color:$menu-font-color-selected;
        }
        .leftnav-label {
color:$menu-font-color-selected;
        }
      }
      .active .item {
        color: $menu-font-color-selected;
      }
    }
  }

  //leftnav item styling
  .item {
    padding: 0px 25px;
    display: flex;
    height: 55px;

    //icon and text of leftnav item
    span {
      color: rgba(255, 255, 255, 0.7);
      font-size: 15px;
      i.icon {
        margin-right: 15px;
        color: rgba(255, 255, 255, 0.7);
        font-size: 15px;
      }
    }

    //Label of leftnav items
    .leftnav-label {
      visibility: visible;
      transition: visibility 0.2s ease-in-out 0.2s;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.5em;
    }

    //alignmnet of items
    .alignment-container {
      display: flex;
      align-items: center;
    }

    //when an item is selected
    &.highlight-item {
      span {
        font-weight: 600;
      }
    }

    //hover over leftnav item
    &:hover {
      background: rgba(255, 255, 255, 0.05);
      span,
      i.icon {
        color: #ffffff;
      }
    }

    // Style if disabled
    &.disabled {
      span,
      i.icon {
        color: $menu-font-color-disabled !important;
        pointer-events: none;
      }
    }
  }

  .active .item {
    background: $menu-background-color-selected;
    color: red;
    span,
    i.icon {
      color: $menu-font-color-selected;
    }
  }

  // Submenu styling
  .submenu {
    .item {
      color: $menu-font-color-sub;
      text-align: left;
      padding-left: 50px;
      height: 40px;
      padding-top: 10px;

      span {
        font-size: 1.15em;
      }

      i.icon {
        display: block;
        float: left;
        font-size: 0.8em;
        margin-right: 10px;
        padding-top: 4px;
      }
    }

    .active .item {
      color: $menu-font-color-selected;
    }
  }
}
.dashboard-content {
  background-image: linear-gradient(147deg, #3b1c5a, #374db1 155%);
  padding-bottom: 100px;
  .topnav {
    top: 20px;
    // padding-left:-30px !important;
  }
}
