.modal-ui {
    max-width: 500px !important;
    overflow: visible;

    // .content {
    //     height: 200px !important;
    // }
}

.location-dropdown {
    background-color: #4C158A;
    color: #e8eef8;
    border: none;
    border-radius: 0.28571429rem;
    font-size: 1em;
    text-align: left;
    height: 40px;
    align-items: center;
    display: inline-flex !important;
    padding-left: 0.9em;
    padding-right: 0.9em;
    width: 140px;

    .text {
        color: black !important;
        margin-right: 10px;
    }

    input {
        top: 5px !important;
    }
}


.view-maintance-image {
    .pg-viewer-wrapper {
        .photo-viewer-container {
            height: 450px !important;
            width: auto !important;
        }
    }
}

.view-maintance-image {
    .pg-viewer-wrapper img {
        width: auto !important;
        height: auto !important;
        max-width: 832px;
        max-height: 100%;
        object-fit: cover;
    }
}

