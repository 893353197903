@import "../../styles/quillpro/quillpro-variables";
@import "../../styles/_variables";

.applicant-detail-container {

  @media screen and (max-width:570px){
    .person-info-card {
      grid-template-areas: 
      "con but"
      "tbl tbl";

      .img {
        display: none;
      }

      .con {
        padding: 1rem 0 0 1rem;
      }
    }


  }

  @media screen and (max-width:992px){

    .person-info-card {
      grid-template: auto / fit-content(100%) auto auto auto;
      grid-template-areas:
      "img con but but"
      "tbl tbl tbl tbl";
    }
    

    .mCSB_draggerContainer {
      position: absolute !important;
      transform: translateX(.7rem);
      background: transparent;

      .mCSB_dragger .mCSB_dragger_bar {
        background-color: $qp-color-primary;
      }
      
      .mCSB_dragger {
        height: 30px !important;
      }
    }
  }
}

.applicant-history-card {

  height: 25rem !important;
  padding: 0;
  margin: 0;
  overflow: hidden;

  .validations-container {
    display: grid;
    grid-template: auto/ 1fr 1fr;
  }

  .validations-link {
    font-size: 1rem;
    float: right;
    
  }

  &-title {
    padding: .25em;
    font-size: 1.5rem;
    color: $qp-color-primary;
    background-color: $qp-color-secondary;
    border-radius: 0.4764em;

    .validations-container {
      
      .validations {
        display: inline-block;
        background-color: $qp-color-secondary;

        &-item {
          display: block;
          font-size: 1rem;
          padding: .1rem .5rem;
          width: fit-content;
          background-color: darken($qp-color-secondary, 10%);
          border-radius: 10rem;
        }
      }
    }
  }

  .credit-title {
    position: absolute;
    text-transform: uppercase;
    color: $qp-color-text;
    font-size: 2rem;
    font-weight: 800;
    left: 10%;
    top: 20%;
    transform: translate(-25%, -40%)
  }
 
  #misdemeanors-tab,
  #felonies-tab {
    padding: 5px;
  }

  #felonies,
  #misdemeanors {
    padding: 0 0;
    position: relative;


    .mCSB_draggerContainer {
      position: absolute !important;
      height: 95% !important;
      transform: translateX(.7rem);
      background: transparent;

      .mCSB_dragger .mCSB_dragger_bar {
        background-color: $qp-color-primary;
      }
      
      .mCSB_dragger {
        height: 30px !important;
      }
    }

    .criminal-table-container {
      padding: 0 .5rem;
    }
  } 
  
  #references, 
  #evictions,
  #employment,
  #rental,
  #messages {
    padding: 0 0;
    position: relative;
  
    .mCSB_draggerContainer {
      background: transparent;
      transform: translateX(.2rem);
  
      .mCSB_dragger .mCSB_dragger_bar {
        background-color: $qp-color-primary;
      }
      
      .mCSB_dragger {
        height: 30px !important;
      }
    }
    
  }

  .references-table-container,
  .evictions-table-container,
  .employment-table-container,
  .rental-table-container,
  .messages-table-container {
    padding: 0 .5rem;
  }
  
  #fico-dial-chart {
    
    .score {
      font-size: 2rem;
      font-weight: 800;
      color: $qp-color-text;
    }
  }

  .messages-table-container {
    
    table {
      tbody {
        tr:last-of-type {
          td {
            position: relative;
            .dropdown-menu {
              transform: translate(-43%, -100%) !important;
            }
          }
        }
      }
    }


    .button-container{
  
      button {
        height: auto;
        line-height:  0.9375rem;
      }

      .dropdown-toggle {
       line-height: 1rem !important;
       display: block !important;
      }
    }
  }
}

.person-info-card {
  display: grid;
  grid-template-areas: 
  "img con tbl tbl but";

  padding: 0 !important;

  .img {
    img {
      width: 100px;
    }
    grid-area: img;
  }

  .con {
    padding: 1rem 0;
    grid-area: con;
  }

  .tbl {
    grid-area: tbl;
  }

  .but {
    grid-area: but;
    align-self: center;
    
    .btn-group {
      display: flex;
      justify-content: flex-end;
      button {
        width: 7rem;
      }
    }
  }
}