.dates {
  // background:red;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  height: 75px;
}

// accounting css
.accounting-wrapper {
  .top-head {
    margin-bottom: 40px;
    h4 {
      font-size: 20px;
      font-weight: 800!important;
      color: #171a1d;
      margin-bottom: 0px;
      font-family: "Be Vietnam", sans-serif !important;
    }
    h6 {
      font-size: 13px;
      font-weight: normal;
      color: #5e6267;
    }
  }
  .table-rw {
    .card {
      box-shadow: unset;
      border: 0px;
      margin-bottom: 30px;
    }
    .banner-image-wrap {
      height: 120px !important;
      position: relative;
      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 3px !important;
      }
      .content {
        position: absolute;
        top: 0px;
        bottom: 0px;
        padding: 37px;
        width: 100%;
        z-index: 9;
        .header,
        .description {
          color: #ffffff;
          text-align: left;
        }
        .header {
          font-size: 18px;
          font-weight: 800;
          line-height: 25px;
        }
        .description {
          margin-top: 0px;
          font-size: 12px;
          font-weight: normal;
        }
        img {
          position: absolute;
          top: 3px;
          right: 3px;
          cursor: pointer;
        }
      }
      &:after {
        content: "";
        position: absolute;
        left: 0px;
        right: 0px;
        width: 100%;
        bottom: 0px;
        background-image: linear-gradient(to bottom, #171a1d -23%, rgba(23, 26, 29, 0));
        height: 100%;
        visibility: visible;
        border-radius: 3px;
      }
    }
    .assets-wrapper {
      border-radius: 3px;
      box-shadow: 0 11px 14px -10px #e5eced;
      height: 120px !important;
      .content {
        display: flex;
        padding: 0px;
        .ui.grid {
          width: 50%;
          margin: 0px;
          overflow: hidden;
        }
        .column {
          width: auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: 12px;
          font-weight: normal;
          color: #5e6267;
          .header {
            font-size: 15px;
            color: #171a1d;
          }
        }
      }
      .header {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 800;
        color: #3b1c5a;
        text-transform: uppercase;
      }
      .left-wrap {
        background-image: url("/assets/img/income-inequality.svg");
        background-repeat: no-repeat;
        background-position: top 16px left 30px;
        background-size: 140px;
        .header {
          padding-left: 245px;
        }
      }
      .right-wrap {
        display: flex;
        align-items: center;
        .line-seperator {
          padding: 0px;
          border: 1px solid rgba(25, 21, 21, 0.1);
          margin: 0px 100px;
          height: 30px;
        }
      }
    }
    .running-wrapper {
      border-radius: 3px;
      box-shadow: 0 11px 14px -10px #e5eced;
      width: 100%;
      h4 {
        font-size: 17px;
        font-weight: 800;
        color: #3b1c5a;
      }
      .content {
        padding: 25px 20px;
      }
    }
    .graph-data-wrapper {
      .ui.card {
        box-shadow: 0 11px 14px -10px #e5eced!important;
      }
      .content {
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
      .ui.column.stripe-data {
        border-radius: 3px;
        background-color: rgba(94, 98, 103, 0.05);
        height: 65px;
        padding: 15px 30px;
        margin: 25px 0px !important;
        .column {
          padding: 0px;
          display: flex;
          flex-direction: column;
          span {
            font-size: 13px;
            font-weight: 500;
            color: #171a1d;
            font-family: "Be Vietnam", sans-serif !important;
          }
          label {
            font-size: 11px;
            font-weight: normal;
            font-family: "Be Vietnam", sans-serif !important;
          }
        }
      }
    }
    .header {
      display: flex !important;
      justify-content: space-between;
      h4 {
        font-size: 17px;
        font-weight: 800 !important;
        color: #3b1c5a;
        font-family: "Be Vietnam", sans-serif !important;
      }
      .right-col {
        span {
          font-size: 12px;
          font-weight: normal;
          color: #5e6267;
          padding-right: 10px;
        }
        select {
          border: 1px solid #e3e3e3;
          height: 35px;
          border-radius: 3px;
          width: 130px;
          font-size: 12px;
          font-weight: normal;
          padding: 0px 10px;
          color: #5e6267;
          cursor: pointer;
        }
      }
    }
  }
}
.hoverableHeader:hover {
 background:#c4bbce !important;
 cursor: pointer !important
}
.hoverableHeader {
  span {
    float:right;
    font-weight:bolder;
    font-size: 100%
  }
}
.filterInputBill {
  input {
    height:14px !important
  }
}

.segmentLoading {
  // background:red !important;
  .progress {
    // background:red !important;
    width:85%;
   top:50%;
   left:7%;
  }
  .header::before {
    background:#f9fafc !important;
  }
}

.billactiongroup {
  .billactionapprove {
    background:green !important;
    color:white !important
  }
  .billactiondelete {
    background:#FF7F7F !important;
    color:white !important
  }
  .billactionvoid {
    background:#FF7F7F !important;
    color:white !important
  }
  .billactionapprove:hover {
    background:grey !important;
  }
  .billactiondelete:hover {
    background:grey !important;
  }
  .billactionvoid:hover {
    background:grey !important;
  }

}

.bills-times{
  // background:red;
  input {
    width:92% !important;
    height:inherit !important;
  }
}
.select-ledgers {
  .menu.visible.transition {
    height:150px !important
  }
}