/* 
	DO NOT DELETE
	THIS SCSS/CSS FILE IS USED BY SCSS FILES WITHING THE React/view AND React/layout folders
	
 */
/* QUILLPRO VARIABLES */
/* 
	DO NOT DELETE
	THIS SCSS/CSS FILE IS USED BY SCSS FILES WITHING THE React/view AND React/layout folders
	
 */
/* QUILLPRO MIXINS */
/* Colors */
@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam:wght@300;400;500;600;700;800&display=swap");
@font-face {
  font-family: 'Avenir-light', sans-serif;
  font-weight: 300;
  font-display: swap;
  src: url(/assets/fonts/Avenir-Book.ttf); }

@font-face {
  font-family: 'Avenir-regular', sans-serif;
  font-weight: 400;
  font-display: swap;
  src: url(/assets/fonts/Avenir-Roman.ttf); }

@font-face {
  font-family: 'Avenir-bold', sans-serif;
  font-weight: 600;
  font-display: swap;
  src: url(/assets/fonts/Avenir-Medium.ttf); }

@font-face {
  font-family: 'Avenir-black', sans-serif;
  font-weight: 900;
  font-display: swap;
  src: url(/assets/fonts/Avenir-Heavy.ttf); }

/* 
	DO NOT DELETE
	THIS SCSS/CSS FILE IS USED BY SCSS FILES WITHING THE React/view AND React/layout folders
	
 */
/* QUILLPRO MIXINS */
@media screen and (max-width: 570px) {
  .applicant-detail-container .person-info-card {
    grid-template-areas: "con but" "tbl tbl"; }
    .applicant-detail-container .person-info-card .img {
      display: none; }
    .applicant-detail-container .person-info-card .con {
      padding: 1rem 0 0 1rem; } }

@media screen and (max-width: 992px) {
  .applicant-detail-container .person-info-card {
    grid-template: auto/fit-content(100%) auto auto auto;
    grid-template-areas: "img con but but" "tbl tbl tbl tbl"; }
  .applicant-detail-container .mCSB_draggerContainer {
    position: absolute !important;
    transform: translateX(0.7rem);
    background: transparent; }
    .applicant-detail-container .mCSB_draggerContainer .mCSB_dragger .mCSB_dragger_bar {
      background-color: #4C158A; }
    .applicant-detail-container .mCSB_draggerContainer .mCSB_dragger {
      height: 30px !important; } }

.applicant-history-card {
  height: 25rem !important;
  padding: 0;
  margin: 0;
  overflow: hidden; }
  .applicant-history-card .validations-container {
    display: grid;
    grid-template: auto/ 1fr 1fr; }
  .applicant-history-card .validations-link {
    font-size: 1rem;
    float: right; }
  .applicant-history-card-title {
    padding: .25em;
    font-size: 1.5rem;
    color: #4C158A;
    background-color: #f2fcfb;
    border-radius: 0.4764em; }
    .applicant-history-card-title .validations-container .validations {
      display: inline-block;
      background-color: #f2fcfb; }
      .applicant-history-card-title .validations-container .validations-item {
        display: block;
        font-size: 1rem;
        padding: .1rem .5rem;
        width: fit-content;
        background-color: #c9f2ee;
        border-radius: 10rem; }
  .applicant-history-card .credit-title {
    position: absolute;
    text-transform: uppercase;
    color: #72848c;
    font-size: 2rem;
    font-weight: 800;
    left: 10%;
    top: 20%;
    transform: translate(-25%, -40%); }
  .applicant-history-card #misdemeanors-tab,
  .applicant-history-card #felonies-tab {
    padding: 5px; }
  .applicant-history-card #felonies,
  .applicant-history-card #misdemeanors {
    padding: 0 0;
    position: relative; }
    .applicant-history-card #felonies .mCSB_draggerContainer,
    .applicant-history-card #misdemeanors .mCSB_draggerContainer {
      position: absolute !important;
      height: 95% !important;
      transform: translateX(0.7rem);
      background: transparent; }
      .applicant-history-card #felonies .mCSB_draggerContainer .mCSB_dragger .mCSB_dragger_bar,
      .applicant-history-card #misdemeanors .mCSB_draggerContainer .mCSB_dragger .mCSB_dragger_bar {
        background-color: #4C158A; }
      .applicant-history-card #felonies .mCSB_draggerContainer .mCSB_dragger,
      .applicant-history-card #misdemeanors .mCSB_draggerContainer .mCSB_dragger {
        height: 30px !important; }
    .applicant-history-card #felonies .criminal-table-container,
    .applicant-history-card #misdemeanors .criminal-table-container {
      padding: 0 .5rem; }
  .applicant-history-card #references,
  .applicant-history-card #evictions,
  .applicant-history-card #employment,
  .applicant-history-card #rental,
  .applicant-history-card #messages {
    padding: 0 0;
    position: relative; }
    .applicant-history-card #references .mCSB_draggerContainer,
    .applicant-history-card #evictions .mCSB_draggerContainer,
    .applicant-history-card #employment .mCSB_draggerContainer,
    .applicant-history-card #rental .mCSB_draggerContainer,
    .applicant-history-card #messages .mCSB_draggerContainer {
      background: transparent;
      transform: translateX(0.2rem); }
      .applicant-history-card #references .mCSB_draggerContainer .mCSB_dragger .mCSB_dragger_bar,
      .applicant-history-card #evictions .mCSB_draggerContainer .mCSB_dragger .mCSB_dragger_bar,
      .applicant-history-card #employment .mCSB_draggerContainer .mCSB_dragger .mCSB_dragger_bar,
      .applicant-history-card #rental .mCSB_draggerContainer .mCSB_dragger .mCSB_dragger_bar,
      .applicant-history-card #messages .mCSB_draggerContainer .mCSB_dragger .mCSB_dragger_bar {
        background-color: #4C158A; }
      .applicant-history-card #references .mCSB_draggerContainer .mCSB_dragger,
      .applicant-history-card #evictions .mCSB_draggerContainer .mCSB_dragger,
      .applicant-history-card #employment .mCSB_draggerContainer .mCSB_dragger,
      .applicant-history-card #rental .mCSB_draggerContainer .mCSB_dragger,
      .applicant-history-card #messages .mCSB_draggerContainer .mCSB_dragger {
        height: 30px !important; }
  .applicant-history-card .references-table-container,
  .applicant-history-card .evictions-table-container,
  .applicant-history-card .employment-table-container,
  .applicant-history-card .rental-table-container,
  .applicant-history-card .messages-table-container {
    padding: 0 .5rem; }
  .applicant-history-card #fico-dial-chart .score {
    font-size: 2rem;
    font-weight: 800;
    color: #72848c; }
  .applicant-history-card .messages-table-container table tbody tr:last-of-type td {
    position: relative; }
    .applicant-history-card .messages-table-container table tbody tr:last-of-type td .dropdown-menu {
      transform: translate(-43%, -100%) !important; }
  .applicant-history-card .messages-table-container .button-container button {
    height: auto;
    line-height: 0.9375rem; }
  .applicant-history-card .messages-table-container .button-container .dropdown-toggle {
    line-height: 1rem !important;
    display: block !important; }

.person-info-card {
  display: grid;
  grid-template-areas: "img con tbl tbl but";
  padding: 0 !important; }
  .person-info-card .img {
    grid-area: img; }
    .person-info-card .img img {
      width: 100px; }
  .person-info-card .con {
    padding: 1rem 0;
    grid-area: con; }
  .person-info-card .tbl {
    grid-area: tbl; }
  .person-info-card .but {
    grid-area: but;
    align-self: center; }
    .person-info-card .but .btn-group {
      display: flex;
      justify-content: flex-end; }
      .person-info-card .but .btn-group button {
        width: 7rem; }
