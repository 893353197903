@font-face {
  font-family: 'Avenir-light', sans-serif;
  font-weight: 300;
  font-display: swap;
  src: url(/assets/fonts/Avenir-Book.ttf);
}
@font-face {
  font-family: 'Avenir-regular', sans-serif;
  font-weight: 400;
  font-display: swap;
  src: url(/assets/fonts/Avenir-Roman.ttf);
}
@font-face {
  font-family: 'Avenir-bold', sans-serif;
  font-weight: 600;
  font-display: swap;
  src: url(/assets/fonts/Avenir-Medium.ttf);
}
@font-face {
  font-family: 'Avenir-black', sans-serif;
  font-weight: 900;
  font-display: swap;
  src: url(/assets/fonts/Avenir-Heavy.ttf);
}

