/* 
	DO NOT DELETE
	THIS SCSS/CSS FILE IS USED BY SCSS FILES WITHING THE React/view AND React/layout folders
	
 */
/* QUILLPRO VARIABLES */
/* 
	DO NOT DELETE
	THIS SCSS/CSS FILE IS USED BY SCSS FILES WITHING THE React/view AND React/layout folders
	
 */
/* QUILLPRO MIXINS */
/* Colors */
@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam:wght@300;400;500;600;700;800&display=swap");
@font-face {
  font-family: 'Avenir-light', sans-serif;
  font-weight: 300;
  font-display: swap;
  src: url(/assets/fonts/Avenir-Book.ttf); }

@font-face {
  font-family: 'Avenir-regular', sans-serif;
  font-weight: 400;
  font-display: swap;
  src: url(/assets/fonts/Avenir-Roman.ttf); }

@font-face {
  font-family: 'Avenir-bold', sans-serif;
  font-weight: 600;
  font-display: swap;
  src: url(/assets/fonts/Avenir-Medium.ttf); }

@font-face {
  font-family: 'Avenir-black', sans-serif;
  font-weight: 900;
  font-display: swap;
  src: url(/assets/fonts/Avenir-Heavy.ttf); }

/* 
	DO NOT DELETE
	THIS SCSS/CSS FILE IS USED BY SCSS FILES WITHING THE React/view AND React/layout folders
	
 */
/* QUILLPRO MIXINS */
.x-hellosign-embedded__iframe {
  min-height: 400px;
  background-color: #F7F8F9;
  height: 84% !important;
  border: none;
  min-width: 80% !important;
  margin: 82px 0px 0px 200px !important; }

.x-hellosign-embedded__modal-close {
  position: absolute;
  top: 42px !important;
  right: 5px !important;
  height: 40px;
  width: 40px; }

.iframe-wrapper {
  min-height: 100px !important; }

.review-background {
  margin-left: 15px !important; }

iframe {
  border: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 300px; }

.applications-container .card.on {
  border-top: 1px solid #4C158A !important;
  border-right: 1px solid #4C158A !important;
  border-left: 1px solid #4C158A !important;
  transform: translateY(10px);
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important; }

.applications-container .application-card-grid {
  display: grid;
  grid-template-columns: fit-content(5.2rem) auto auto fit-content(5.2rem);
  grid-template-areas: "img adr ale but"; }
  .applications-container .application-card-grid .img {
    grid-area: img; }
    .applications-container .application-card-grid .img .property-img {
      width: 5.2rem;
      border-bottom-left-radius: .4673rem !important;
      border-top-left-radius: .4673rem !important; }
  .applications-container .application-card-grid .but {
    grid-area: but; }
    .applications-container .application-card-grid .but .notification-number {
      width: 20px;
      height: 20px;
      display: inline-block;
      border-radius: 50%;
      background: red;
      text-align: center;
      line-height: 20px;
      font-size: 10px;
      color: #ffffff;
      box-shadow: -16px -12px 46px 0px rgba(0, 0, 0, 0.4);
      position: absolute;
      right: -5px;
      top: -5px; }
  .applications-container .application-card-grid .ale {
    grid-area: ale;
    align-self: end;
    justify-self: end;
    padding-right: 1rem;
    font-size: 1.5rem;
    transform: translateY(-10px); }
    .applications-container .application-card-grid .ale .notification-number {
      width: 20px;
      height: 20px;
      display: inline-block;
      border-radius: 50%;
      background: red;
      text-align: center;
      line-height: 20px;
      font-size: 10px;
      color: #ffffff;
      position: relative;
      top: -15px; }
  .applications-container .application-card-grid .adr {
    grid-area: adr;
    align-self: center; }

.applications-container table.table-hover {
  overflow: hidden; }
  .applications-container table.table-hover tbody tr:hover.tenant-row {
    color: white; }
  .applications-container table.table-hover tbody td {
    text-transform: capitalize; }

.applications-container .table-applications {
  border-collapse: separate;
  border-spacing: 0;
  border-bottom: 1px solid #4C158A;
  border-right: 1px solid #4C158A;
  border-left: 1px solid #4C158A;
  border-bottom-left-radius: .4673rem !important;
  border-bottom-right-radius: .4673rem !important;
  background: white; }
  .applications-container .table-applications thead {
    background: #f2fcfb !important; }

.applications-container .adr {
  font-size: 1.5rem;
  display: inline-block; }
  .applications-container .adr span {
    vertical-align: bottom; }
  .applications-container .adr .alert {
    margin: 0;
    margin-top: 1rem;
    vertical-align: text-bottom; }

@media screen and (max-width: 767px) {
  .applications-container .application-card-grid .adr {
    display: grid;
    grid-template: auto / 1fr; }
    .applications-container .application-card-grid .adr span {
      font-size: 1.2rem; }
  .applications-container .application-card-grid .ale {
    font-size: 1.2rem; } }

@media screen and (max-width: 992px) {
  .applications-container .application-card-grid {
    display: grid;
    grid-template-areas: "img adr adr ale" "but but but but"; }
    .applications-container .application-card-grid .img {
      grid-area: img; }
    .applications-container .application-card-grid .but {
      grid-area: but;
      width: 100%; }
      .applications-container .application-card-grid .but button {
        transform: translate(3px, 6px);
        width: 100%; }
    .applications-container .application-card-grid .ale {
      grid-area: ale;
      justify-self: end; }
    .applications-container .application-card-grid .adr {
      grid-area: adr;
      font-size: 1.2rem; } }

.form-steps-font {
  font-weight: bold;
  color: black; }

.text-area {
  height: '150px';
  max-height: '500px';
  border-radius: '5px';
  border: '1px solid #4C158A'; }

.application-step-wrapper {
  margin: 15px 0 10px 0; }
  .application-step-wrapper .ui.steps {
    border: 0; }
    .application-step-wrapper .ui.steps .step {
      background: #F3F4F5;
      padding-top: 12px;
      padding-bottom: 12px; }
      .application-step-wrapper .ui.steps .step .description {
        font-weight: bold; }
      .application-step-wrapper .ui.steps .step::after {
        background: #F3F4F5; }
      .application-step-wrapper .ui.steps .step.active {
        background: #4C158A;
        border-right-color: #4C158A; }
        .application-step-wrapper .ui.steps .step.active::after {
          background: #4C158A;
          border-color: #4C158A; }
        .application-step-wrapper .ui.steps .step.active .description {
          color: #fff; }

.page-head {
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 1.71428571rem;
  color: rgba(0, 0, 0, 0.87); }

.add-new-app-head {
  margin: 0 0 20px 0; }
  .add-new-app-head p {
    font-weight: 700;
    font-size: 14px; }

.move-date {
  max-width: calc(100% / 3);
  width: calc(100% / 3);
  padding-left: 0.5em;
  padding-right: 0.5em; }
  .move-date .form-control {
    box-sizing: border-box;
    height: 38px; }
  .move-date .move-date-label {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    font-family: "Avenir-regular", sans-serif;
    font-weight: 300;
    margin-bottom: 7px; }

.modal .move-date {
  max-width: 100%;
  width: 100%; }

.unit-info-form, .primary-applicant-form, .co-applicant-form {
  margin-top: 25px; }
  .unit-info-form.ui.form .field > label, .primary-applicant-form.ui.form .field > label, .co-applicant-form.ui.form .field > label {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    margin-bottom: 7px; }
  .unit-info-form.ui.form [class*="equal width"].fields.unit-info-form-first-row > .field, .primary-applicant-form.ui.form [class*="equal width"].fields.unit-info-form-first-row > .field, .co-applicant-form.ui.form [class*="equal width"].fields.unit-info-form-first-row > .field {
    width: calc(100% / 3); }
  .unit-info-form.ui.form .fields:not(.grouped):not(.inline) .field:not(:only-child) .ui.checkbox, .primary-applicant-form.ui.form .fields:not(.grouped):not(.inline) .field:not(:only-child) .ui.checkbox, .co-applicant-form.ui.form .fields:not(.grouped):not(.inline) .field:not(:only-child) .ui.checkbox {
    margin-top: 0; }
  .unit-info-form.ui.form .fields .radio-col .field, .primary-applicant-form.ui.form .fields .radio-col .field, .co-applicant-form.ui.form .fields .radio-col .field {
    display: inline-block; }
  .unit-info-form.ui.form textarea, .primary-applicant-form.ui.form textarea, .co-applicant-form.ui.form textarea {
    resize: none;
    height: 60px; }

.radio-col {
  width: calc(100% / 3);
  padding-left: 0.5em;
  padding-right: 0.5em; }
  .radio-col .radio-col-label {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    margin-bottom: 7px;
    font-weight: bold; }

.pets-col {
  width: calc(100% / 3); }

.radio-wrapper {
  height: 38px;
  display: flex;
  align-items: center; }
  .radio-wrapper label {
    margin-bottom: 0; }

.margin-top-2 .ui.fluid.input {
  margin-top: -2px; }

.emergency-contact-last-row .pets-col {
  width: 25%; }

.primary-applicant-header {
  color: #343c49;
  font-size: 18px;
  font-weight: bold;
  margin: 25px 0 20px 0; }

.primary-applicant-subheader {
  color: #343c49;
  font-size: 16px;
  font-weight: bold;
  margin: 0px 0 18px 0; }

.ui.segment.primary-applicant-segment {
  box-shadow: none;
  padding: 18px 18px 12px 18px; }
  .ui.segment.primary-applicant-segment.merge-section:not(:last-child) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0; }
  .ui.segment.primary-applicant-segment.merge-section + .merge-section {
    border-top: 0;
    border-top-left-radius: 0;
    margin-top: 0; }

.ui.segment.primary-applicant-main-segment {
  box-shadow: none;
  padding: 0;
  margin: 0;
  border: 0; }

.segment-header-box {
  background: rgba(127, 135, 147, 0.05);
  margin-left: -19px;
  margin-right: -19px;
  color: #fff;
  padding: 8px 18px;
  margin-bottom: 18px;
  height: 52px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15); }
  .segment-header-box .primary-applicant-subheader {
    margin: 0; }

.wid-110 {
  width: 110px; }

.mt-18 {
  margin-top: 18px; }

.mt-25 {
  margin-top: 25px; }

.ui.positive.button.add-income-button {
  background: none;
  padding: 0; }
  .ui.positive.button.add-income-button:hover, .ui.positive.button.add-income-button:focus, .ui.positive.button.add-income-button:active {
    background: none; }
  .ui.positive.button.add-income-button > .icon:not(.button) {
    opacity: 1;
    color: #4C158A; }

.ui.positive.button.remove-income-button {
  background: none;
  padding: 0;
  position: absolute;
  top: 14px;
  right: 0; }
  .ui.positive.button.remove-income-button:hover, .ui.positive.button.remove-income-button:focus, .ui.positive.button.remove-income-button:active {
    background: none; }
  .ui.positive.button.remove-income-button > .icon:not(.button) {
    opacity: 1;
    color: #f44336; }

.income-input-row {
  position: relative; }
  .income-input-row .equal.width.fields {
    width: calc(100% - 35px); }

.background-info-row {
  margin-bottom: 15px; }

.ui.segment:empty {
  padding: 0;
  border: 0; }

.ui.styled.accordion.custom-accordian {
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15); }
  .ui.styled.accordion.custom-accordian .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    padding-bottom: 12px;
    color: #343c49;
    font-size: 15px;
    background: rgba(34, 36, 38, 0.05); }
    .ui.styled.accordion.custom-accordian .title:hover {
      background: rgba(34, 36, 38, 0.05); }
    .ui.styled.accordion.custom-accordian .title.active:hover {
      background: transparent; }

.occupant-table {
  border-collapse: collapse !important;
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.1);
  border: 0; }
  .occupant-table > thead > tr > th {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 0.78571429em;
    padding-bottom: 0.78571429em;
    background: #F9FAFB;
    color: rgba(0, 0, 0, 0.87);
    text-align: left;
    border-top: 0; }
  .occupant-table > tbody > tr > td {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 0.78571429em;
    padding-bottom: 0.78571429em;
    vertical-align: middle; }

.occupants-date input[type="text"].form-control {
  margin: 0;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: none;
  height: 38px;
  box-sizing: border-box;
  width: 100%; }

.min-wid-85 {
  min-width: 85px; }

.form-data-wrapper {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  margin-top: 25px;
  padding: 0; }
  .form-data-wrapper .form-data-col {
    width: calc(100% / 3);
    padding: 8px 15px;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15); }
    .form-data-wrapper .form-data-col:nth-child(3n + 2) {
      border-left: 1px solid rgba(34, 36, 38, 0.15);
      border-right: 1px solid rgba(34, 36, 38, 0.15); }
  .form-data-wrapper .form-data-label {
    font-size: 15px;
    margin-bottom: 2px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: bold; }
  .form-data-wrapper .form-data-text {
    font-size: 14px;
    color: #343c49; }

.applicant-info-box {
  display: flex;
  flex-wrap: wrap;
  border-radius: 0;
  margin-top: 0;
  padding: 0 0 8px 0; }
  .applicant-info-box .applicant-info-col {
    width: calc(100% / 3);
    padding: 8px 15px; }
  .applicant-info-box .applicant-info-label {
    font-size: 15px;
    margin-bottom: 2px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: bold; }
  .applicant-info-box .applicant-info-text {
    font-size: 14px;
    color: #343c49; }

.table-container.application-table tr th {
  background: #343c49; }
  .table-container.application-table tr th::before {
    top: 50%;
    margin-top: -2.5px; }
  .table-container.application-table tr th:nth-child(3), .table-container.application-table tr th:nth-child(7), .table-container.application-table tr th:nth-child(8) {
    cursor: pointer;
    height: 57px;
    padding-right: 18px; }
    .table-container.application-table tr th:nth-child(3) .header-filter-wrapper, .table-container.application-table tr th:nth-child(7) .header-filter-wrapper, .table-container.application-table tr th:nth-child(8) .header-filter-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row-reverse; }
    .table-container.application-table tr th:nth-child(3)::before, .table-container.application-table tr th:nth-child(7)::before, .table-container.application-table tr th:nth-child(8)::before {
      right: 10px; }

.first-lastname-text {
  text-transform: capitalize; }

.co-applicant-text {
  display: inline-block;
  margin-right: 12px; }

.decision-summary-box {
  display: flex;
  flex-wrap: wrap;
  padding: 8px 0;
  border: 1px solid rgba(34, 36, 38, 0.15);
  background: rgba(34, 36, 38, 0.05);
  border-radius: 0.28571429rem; }
  .decision-summary-box .decision-summary-col {
    width: calc(100% / 3);
    padding: 8px 15px; }
  .decision-summary-box .decision-summary-label {
    font-size: 15px;
    margin-bottom: 2px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: bold; }
  .decision-summary-box .decision-summary-text {
    font-size: 14px;
    color: #343c49; }

.application-status {
  display: inline-block;
  background: #4C158A;
  color: #fff;
  border-radius: 20px;
  padding: 8px 18px;
  font-weight: bold; }

.button-link {
  background: #4C158A;
  color: #fff;
  font-weight: bold;
  padding: 9px 20px;
  border-radius: 0.28571429rem; }

.no-results-text {
  font-weight: bold;
  font-size: 16px; }

.background-check-text {
  display: block;
  color: #72848c; }

th.required-field::after {
  content: "*";
  color: #db2828;
  display: inline-block;
  vertical-align: top;
  margin: -.2em 0 0 .2em; }

.review-address-heading {
  padding: 15px 15px 0 15px;
  font-size: 15px;
  margin-bottom: 2px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: bold;
  border-top: 1px solid rgba(34, 36, 38, 0.15); }

.review-address-paragraph {
  padding: 0 15px;
  font-size: 14px;
  color: #343c49; }

.co-applicant-primary-address .pets-col {
  width: 100%; }

.decision-summary-sub-head {
  display: block;
  color: rgba(0, 0, 0, 0.7);
  font-weight: bold;
  margin: 12px 0; }

.no-results-text-application {
  max-width: 500px;
  margin: auto; }

.not-found-icon {
  width: 109px;
  height: 136px;
  display: inline-block;
  background: url("/assets/img/no-found.png");
  background-repeat: no-repeat;
  background-size: 100%;
  opacity: 0.5; }

.app-content .ui.form .disabled.fields .field,
.app-content .ui.form .disabled.field,
.app-content .ui.form .field :disabled {
  opacity: 1;
  cursor: not-allowed !important;
  pointer-events: auto; }

.app-content .ui.form .field.disabled > label,
.app-content .ui.form .fields.disabled > label,
.app-content .ui.disabled.input,
.app-content .ui.input:not(.disabled) input[disabled] {
  opacity: 1; }

.application-checkbox {
  max-width: 180px; }
  .application-checkbox .ui.checkbox {
    margin-top: 36px !important; }
