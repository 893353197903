@import "../../SiteShared.scss";
.top-nav-item-point {
  background-color: rgba(59, 28, 90, 0.05);
  display: flex;
  align-items: center;
  width: 138px;
  height: 35px;
  justify-content: center;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
  color: #3b1c5a;
  a {
    &:hover {
      color: unset;
    }
  }
  .points {
    padding-right: 5px;
  }
}

.topnav.ui.menu.fixed {
  height: 85px;
  background-color: #f5f5f5;
  margin-left: $leftnav-width-expanded;
  border-radius: 6px 6px 0px 0px !important;
  box-shadow: unset;
  border: 0px;
  margin-right:30px;
  position: absolute;
  padding: 25px 50px;
  //Adjust top navbar size and layout for non-phone screen sizes
  @include not-phone {
    width: calc(100% - #{$leftnav-width-expanded + 20px});
    transition: width 0.5s ease-in-out 0.2s;
    transition: margin 0.5s;
    top: 20px;
    right: 20px;
    left: auto;
    &.expanded {
      margin-left: 80px;
      width: calc(100% - #{80px});
      transition: width 0.5s;
      transition: margin 0.5s;
    }

    .logo-small {
      display: none;
    }
  }

  //Adjust top navbar size and layout for phone screen size
  @include for-phone {
    margin-left: 0px;
    width: 100%;

    .logo-small {
      height: 40px;
      margin-top: 12px;
      padding-left: 5px;
      padding-right: 10px;
    }
  }

  // Top navbar items (menu icon, notification icon, etc.)
  & > .item {
    background-color: #e6e6e6;
    width: 35px;
    height: 35px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    i.icon {
      color: rgba(94, 98, 103, 0.7);
      font-size: 12px;
      margin: 0px;
    }
  }

  // Top navbar search
  .leasera-search {
    margin-left: 10px;
    width: 250px;
    .search-inline {
      padding: 0px;
      height: 100%;
      margin-top: 0px !important;
    }
    .ui.input {
      height: 35px;
      margin: 0px;
      input {
        background-color: #e6e6e6;
        border: none;
        padding: 0px 10px;
        color: #171a1d;
        height: 100%;
        width: 100%;
        border-radius: 6px;
      }

      input::placeholder {
        color: #171a1d;
        @include for-phone {
          color: $menu-font-color-primary;
        }
      }

      i.icon {
        color: $topnav-font-color;
      }
    }

    .search-inline {
      margin-top: 3px;
      @include for-phone {
        display: none;
      }
    }

    .search-dropdown {
      .ui.input {
        width: 100%;
      }

      input {
        width: 100%;
        padding-top: 5px;
        padding-bottom: 5px;
        box-sizing: border-box;
        height: 40px;
        line-height: 1em;
      }

      //menu item text
      span {
        color: $menu-font-color-primary;
      }

      //menu item icon
      i.icon {
        color: $menu-font-color-primary;
      }
    }
  }

  .property-selector {
    .property-dropdown {
      background-color: #e6e6e6;
      color: #171a1d;
      border: none;
      border-radius: 0.28571429rem;
      font-size: 1em;
      text-align: left;
      height: 35px;
      padding-left: 0.9em;
      padding-right: 0.9em;
      width: 160px;
      border-radius: 6px;
      input {
        font-size: 14px;
        line-height: 26px;
        color: #171a1d;
        height: 35px;
      }

      input::placeholder {
        color: $topnav-font-color;
      }
      .text {
        top: 10px;
        font-size: 13px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100px;
      }
      .dropdown.icon {
        position: absolute;
        right: 15px;
        top: 10px;
        &::before {
          content: "\f107" !important;
          font-family: "FontAwesome";
          color: rgba(0, 0, 0, 0.5);
        }
      }
      &.visible {
        .text {
          color: #171a1d;
        }
      }

      .menu {
        background-color: $menu-background-color-dropdown;
        width: 100%;
        max-height: 20em;

        .text,
        .message {
          color: $menu-font-color-primary;
        }
      }

      &.property-selector-mobile {
        width: 100%;

        div.text {
          line-height: 45px;
          color: $menu-font-color-primary;
        }

        i.icon {
          display: none;
        }
      }
    }

    @include for-phone {
      padding: 0px;
    }
  }

  // Topnav bar notification icon label count
  .notif-count.ui.floating.label {
    top: -9px;
    min-width: 0px;
    min-height: 0px;
    width: 15px;
    height: 15px;
    left: 34px;
    line-height: 5px;
  }

  .notif-number {
    &-1dig {
        position: absolute;
        left: 4px;
    }
    &-2dig {
        position: absolute;
        left: 1.5px;
    }
      &-3dig {
        position: absolute;
        left: 0.5px;
        font-size: 8px;
    }
  }

  // Topnav bar dropdown user menu
  .user-menu {
    width: 35px;
    height: 35px;
    padding: 0px;
    background-color: #e6e6e6;
    border-radius: 6px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    //font on topnav of user menu
    i.icon {
      color: #5e6267;
      margin: 0px;
      &::before {
        content: "\f007";;
        font-size: 13px;
      }
      &.alarm {
        &::before {
          content: "\f0f3";
        }
      }
      &.bars {
        &::before {
          content: "\2630 "
        }
      }
    }

    &.alarm {
      margin-right: 14px;
    }


    //icon on topnav of user menu
    i.dropdown.icon {
      display: none;
    }

    //text on topnav of user menu
    span {
      color: $topnav-font-color;
      font-size: 1.15em;
    }

    // Hide name on smaller screen
    .name {
      display: none;
    }

    //the menu dropdown
    &.dropdown.item .menu {
      display: none;
      // background-color: $menu-background-color-dropdown;
      width: 350px;
      padding: 20px 0 0;
      border-radius: 3px !important;
      box-shadow: 0 11px 14px -10px #e5eced !important;
      background-color: #ffffff;
      border: 0px;
      padding-top: 0px;
      top: 54px;
      //menu item text
      span {
        color: $menu-font-color-primary;
      }

      //menu item icon
      i.icon {
        color: $menu-font-color-primary;
      }
      &.notification-wrap {
        .ui.feed {
          margin-top: 0px;
          overflow-x: hidden;
        }
        .dropdown-top-menu-user-info {
          padding: 20px;
          border-bottom: 1px solid rgba(94, 98, 103, 0.1);
          p {
            font-size: 14px;
            font-weight: 500;
            color: #171a1d;
          }
        }
        .detail-txt {
          display: flex;
          padding: 20px;
          .event {
            margin-left: 10px;
            width: calc(100% - 40px);
            .summary {
              font-size: 13px;
              font-weight: normal;
              line-height: 22px;
              color: #171a1d;
              white-space: initial;
            }
            .meta {
              font-size: 12px;
              font-weight: normal;
              color: #5e6267;
            }
          }
        }
        .ui.divider {
          margin: 0px;
        }
        .icon-block {
          width: 35px;
          height: 35px;
          padding: 5px;
          background-color: rgba(59, 28, 90, 0.1);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          color: #3b1c5a !important;
          i {
            color: #3b1c5a;
            margin: 0px;
          }
        }
      }
      &.profile-list {
        .item{
          height: 45px;
          display: flex!important;
          align-items: center;
          background-repeat: no-repeat!important;
          background-position: center left 15px !important;
          padding: 10px 45px!important;
          span{
            font-size: 14px!important;
            font-weight: normal;
            color: #171a1d;
          }
          &.application-txt{
            background-image: url('/assets/img/icons-application.svg')!important;
          }
          &.friends-txt{
            background-image: url('/assets/img/icons-friends.svg')!important;
          }
          &.signout-txt{
            background-image: url('/assets/img/icons-sign-out.svg')!important;
          }
        }
        .dropdown-top-menu-user-info {
          display: flex;
          padding: 20px;
          .user-image {
            width: 80px;
            height: 80px;
            img {
              width: 100%;
            }
          }
          .user-details {
            margin-left: 15px;
            .user-name {
              font-size: 15px;
              font-weight: 600;
              color: #3b1c5a;
              font-family: "Be Vietnam", sans-serif !important;
            }
            .email-id {
              font-size: 13px;
              font-weight: 500;
              color: #5e6267;
              font-family: "Be Vietnam", sans-serif !important;
            }
            .profile-rw {
              opacity: 1;
              border-radius: 3px;
              background: rgba(59, 28, 90, 0.1);
              width: 195px;
              height: 30px;
              padding: 0px;
              margin-top: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              .text {
                font-size: 12px;
                color: #3b1c5a;
                font-weight: 800;
                text-transform: uppercase;
                font-family: "Be Vietnam", sans-serif !important;
              }
            }
          }
        }
      }
    }
  }
}
.ui.menu.fixed.topnav .item.notification-col{
  width: 35px;
    height: 35px;
    padding: 0px!important;
    background-color: #e6e6e6!important;
    border-radius: 6px!important;
    margin-right: 12px;
    justify-content: center;
}
.phone-only {
  @include not-phone {
    display: none !important;
  }
}

.phone-hide {
  @include for-phone {
    display: none !important;
  }
}

.nav-icon {
  color: $topnav-font-color;
  height: 100%;

  i.icon {
    font-size: 1.25em;
  }
}

.top-drawer {
  .td-trigger.item {
    color: $topnav-font-color;
    height: 100%;
    font-size: 1.25em;
  }

  .td-close {
    text-align: center;
    margin-top: 13px;
    font-size: 0.85em;
  }

  .td-drawer {
    visibility: hidden;
    background-color: $menu-background-color-dropdown;
    position: fixed;
    z-index: 999;
    left: 0px;
    width: calc(100vw);
    height: 0px;
    transition: 0.5s ease-in-out;
    padding: 5px;
    top: $topnav-height;

    * {
      transition: 0.25s ease-in-out;
      transition-delay: 0s;
      visibility: hidden;
    }

    &.td-visible {
      visibility: visible;
      height: 90px;
      transition: 0.5s ease-in-out;

      * {
        transition: 0.25s ease-in-out;
        transition-delay: 0.4s;
        visibility: visible;
      }
    }
  }

  .td-backdrop {
    visibility: hidden;
    transition: 0.5s ease-in-out;
    background-color: rgba(255, 255, 225, 0);
    position: fixed;
    top: $topnav-height;
    left: 0px;
    right: 0px;
    bottom: 0px;

    &.td-visible {
      visibility: visible;
      background-color: $backdrop-background-color;
      z-index: 1;
    }
  }
}

.toggle-alerts {
  border-radius: 3px;
  border: none;
  background-image: linear-gradient(102deg, #3b1c5a, #374db1 163%);
  color: white;
  position: absolute;
  right: 4px;
  top: 8px;
  font-weight: bold;
  font-size: 13px;
}