@import "../../styles/quillpro/quillpro-variables";
@import "../../styles/_variables";

.graphic {
  display: block;
  height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.semantic-Loader {
  vertical-align: middle;
  width: 100%;
  display: inline-block;
  height: 50%;
  position: absolute;
  top: 0;
  bottom: 0%;
  margin: auto;
}