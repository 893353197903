@import "../../SiteShared.scss";


.error404 {

  .alertRibbon.ui.ui.ui.ribbon.label {
    background-color: $accent-color;
    color: $primary-color-light;
  }

}
